import styled from 'styled-components'
import { Container } from '../common/common-styles'
import media from '../utils/breakpoints'

export const FaqSectionWrapper = styled.div`
  padding: 1.5rem 0;
  ${media.md} {
    padding: 3rem 0;
    ${Container} {
      padding: 2rem 1rem;
      background: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='1240' height='697'%3E%3Cswitch%3E%3Cg%3E%3Cg fill='%23f8f8ff'%3E%3Ccircle cx='258' cy='413' r='258'/%3E%3Ccircle cx='724' cy='187' r='187'/%3E%3Ccircle cx='689.5' cy='567.5' r='129.5'/%3E%3Ccircle cx='1110.5' cy='423.5' r='129.5'/%3E%3C/g%3E%3C/g%3E%3C/switch%3E%3C/svg%3E")
        2rem center / contain no-repeat;
    }
  }
`
export const FaqInnerWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`
export const FaqSingleWrapper = styled.div`
  padding: 0.5rem 1rem;
  border: 2px solid #ae9dff;
  border-radius: 5px;
  margin: 1rem 0;
  position: relative;
  background: #fff;
  cursor: pointer;
  /* max-height: ${({ open }) => (open ? '400px' : '48px')};
  transition: max-height 1.5s linear; */
  &:after {
    content: '';
    position: absolute;
    right: 10px;
    top: 15px;
    height: 12px;
    width: 12px;
    background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 1.75735L10.2426 6L6 10.2426' stroke='%23fd2d55' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
      center center / contain round;
    transform: ${({ open }) => open && 'rotate(90deg)'};
    transition: transform 350ms ease;
  }
  p.question {
    font-weight: bold;
  }
  p.answer {
    padding-top: 1rem;
    background: #fff;
  }
`
