import styled from 'styled-components'
import { Container } from '../../common/common-styles'
import media from '../../utils/breakpoints'
export const HeroFeatureWrapper = styled.div`
  position: relative;
  z-index: 2;
  ${Container} {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    padding: 2rem;
    div.feature {
      border-radius: 10px;
      padding: 1rem 1.5rem;
      background-color: #f8f7fc;
      transition: background-color 350ms ease;
      svg {
        height: 48px;
        width: 48px;
        display: block;
      }
      h3 {
        font-size: 20px;
        padding: 0.5rem 0;
      }
      p {
        font-size: 0.9rem;
        line-height: 1.3;
      }

      &:hover {
        background-color: #f1eff8;
      }
    }
    background: url("data:image/svg+xml,%3Csvg width='1144' height='280' viewBox='0 0 1144 280' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 96.7421C1 96.7421 124.672 -0.294895 178.34 1.01311C200.944 1.55911 166.465 37.4571 178.578 39.2451C190.692 41.0341 224.84 5.74711 266.933 18.9951C332.25 39.5481 259.692 166.494 397.743 208.02C535.793 249.546 595.441 175.357 674.683 172.128C753.923 168.9 925.543 284.984 990.466 278.758C1055.39 272.532 850.564 98.4511 890.774 64.7681C913.75 45.5241 967.242 76.7151 988.222 71.8041C1009.2 66.8921 980.115 22.6241 998.239 18.9351C1016.36 15.2471 1143 84.7701 1143 84.7701' stroke='%23512DA8' stroke-width='3'/%3E%3C/svg%3E%0A")
      center center / contain no-repeat;
  }

  ${media.sm} {
    ${Container} {
      grid-template-columns: repeat(3, 1fr);
    }
  }
`
