const faq = [
  {
    question: 'Will My Hosting Plan come with a cPanel ?',
    answer: 'Yes All our Hosting Plans will come with cPanel.'
  },
  {
    question:
      'Can I switch between plans? If so, how often? Are there any charges involved?',
    answer:
      'Yes, We offer One-Click Upgrade and Downgrade Option without any restriction. If you Upgrade you just have to pay the difference between the plans.'
  },
  {
    question: 'Will you provide Backup of my website data ?',
    answer:
      'We maintain weekly backup of all the accounts. If you ever face any issues we will provide you a backup of your website.'
  },
  {
    question:
      'Can You transfer my Website outside of Blue Geek Hosting to Blue Geek Hosting?',
    answer:
      'Yes, We offer free Website migration. You have create a support ticket from Client area to get your website transfered.'
  },
  {
    question: 'Do we accept PAYTM as a payment option ?',
    answer:
      'Yes we Do but you have to select CCAvenue as payment gateway in Checkout Page.'
  },
  {
    question: 'Is there a dedicated Account Manager for my account?',
    answer: 'Right Now we dont offer a dedicated Account Manager.'
  },
  {
    question: 'What are the nameservers for my domain?',
    answer:
      "Refer to the email sent to your registered email address for the nameservers details. If you can't find it, contact us."
  },
  {
    question: 'What’s The Refund Policy ?',
    answer:
      'You should feel free to ask for a refund. We understand your concern and we will surely help you about it. However, make sure you are asking for a refund within 30 days of your purchase date. As well you have to give us at least two business day to issue your refund case.'
  }
]

export default faq
