import React from 'react'
import { FaqSingleWrapper } from './faq-styles'
import icon from '../../images/icons/icons.svg'

function SingleFaq({ faq, index, updateFaq }) {
  const { question, answer, open } = faq

  const handleClick = () => {
    updateFaq(index)
  }

  return (
    <FaqSingleWrapper
      open={open}
      onClick={handleClick}
      tabIndex="0"
      name="Open FAQ"
      onKeyPress={handleClick}
      role="button"
    >
      <p className="question ">{question}</p>
      {open && (
        <p className="answer">
          <svg width="12" height="12" stroke="var(--blue)">
            <use href={`${icon}#arrow`} />
          </svg>{' '}
          <span>{answer}</span>
        </p>
      )}
    </FaqSingleWrapper>
  )
}

export default SingleFaq
