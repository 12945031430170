import React from 'react'
import icon from '../../../images/icons/icons.svg'
import { HeroFeatureWrapper } from './hero-feature-styles'
import { Container } from '../../common/common-styles'
function HeroFeatures() {
  return (
    <HeroFeatureWrapper>
      <Container>
        <div className="feature">
          <svg>
            <use href={`${icon}#feature-fast`} />
          </svg>
          <h3>Ultimate Speed</h3>
          <p>
            Get impressive load time, backed by the Google Cloud Platform. No
            configuring needed!
          </p>
        </div>
        <div className="feature">
          <svg>
            <use href={`${icon}#feature-backup`} />
          </svg>
          <h3>Daily Backup</h3>
          <p>
            Automatic Daily Backups for free. Keep Your Site Data safe with
            Daily Backups.
          </p>
        </div>
        <div className="feature">
          <svg>
            <use href={`${icon}#feature-migration`} />
          </svg>
          <h3>Free Migrations</h3>
          <p>
            Make changes in a test environment, without setting up servers or
            plugins.
          </p>
        </div>
      </Container>
    </HeroFeatureWrapper>
  )
}

export default HeroFeatures
