import React from 'react'
import {
  FeatureSectionWrapper,
  FeatureSectionInner
} from './feature-section-styles'
import { Container } from '../../common/common-styles'
import icon from '../../../images/icons/icons.svg'
import PrimaryHeading from '../../heading/primary-heading'
function FeatureSection() {
  const title = 'Why choose BlueGeekHosting'
  const subtitle =
    'we focus on successful sites for web designers, developers, bloggers and online businesses. Our community and support helps you create a website fast and easy!'
  return (
    <FeatureSectionWrapper>
      <PrimaryHeading title={title} subtitle={subtitle} />
      <Container>
        <FeatureSectionInner>
          <div className="feature">
            <svg width="48" height="48" viewBox="0 0 48 48">
              <use href={`${icon}#feature-fast`} />
            </svg>
            <h3>Blazing-fast site speed</h3>
            <p>
              Get impressive load time on every site, backed by SSD only servers
              Platform. No configuring needed!
            </p>
          </div>
          <div className="feature">
            <svg width="48" height="48" viewBox="0 0 48 48">
              <use href={`${icon}#feature-secure`} />
            </svg>
            <h3>Free SSL certificates</h3>
            <p>
              Skip third-party providers and install SSL right from your site’s
              dashboard!
            </p>
          </div>
          <div className="feature">
            <svg width="48" height="48" viewBox="0 0 48 48">
              <use href={`${icon}#feature-check`} />
            </svg>
            <h3>Optimized for WordPress</h3>
            <p>
              From our servers to our support team, we’re focused on our
              favorite platform, WordPress!
            </p>
          </div>
          {/* <div className="feature">
            <svg width="48" height="48" viewBox="0 0 48 48">
              <use href={`${icon}#feature-staging`} />
            </svg>
            <h3>Weekly Backup</h3>
            <p>We provide weekly backup for free. No hidden Cost</p>
          </div> */}
          <div className="feature">
            <svg width="48" height="48" viewBox="0 0 48 48">
              <use href={`${icon}#feature-dev`} />
            </svg>
            <h3>cPanel Powered</h3>
            <p>
              The most popular and powerful web hosting panel for easy
              management of your hosting account.
            </p>
          </div>
          <div className="feature">
            <svg width="48" height="48" viewBox="0 0 48 48">
              <use href={`${icon}#feature-feedback`} />
            </svg>
            <h3>Instant support</h3>
            <p>
              We emphasize the sharing of knowledge within our support
              department which makes us great.
            </p>
          </div>
          <div className="feature">
            <svg width="48" height="48" viewBox="0 0 48 48">
              <use href={`${icon}#feature-backup`} />
            </svg>
            <h3>Always backed up</h3>
            <p>
              Automatic Daily Backups for free. Keep Your Site Data safe with
              Weekly Backups.
            </p>
          </div>
        </FeatureSectionInner>
      </Container>
    </FeatureSectionWrapper>
  )
}

export default FeatureSection
