import React, { useState } from 'react'
import faqData from '../../content/faq/faq'
import { FaqSectionWrapper, FaqInnerWrapper } from './faq-styles'
import SingleFaq from './faq-single'
import { Container } from '../common/common-styles'
import PrimaryHeading from '../heading/primary-heading'

function FaqSection() {
  const faqDataset = faqData.map((faq, i) => ({ ...faq, open: !i }))
  const [faqs, setFaqs] = useState(faqDataset)

  const handleFaqClick = j => {
    setFaqs(faqs.map((faq, i) => ({ ...faq, open: i === j })))
  }
  const title = 'Frequently Asked Questions'
  const subtitle =
    'Here is all the information you need to know. Type in the search field and click browse.'
  return (
    <FaqSectionWrapper>
      <PrimaryHeading title={title} subtitle={subtitle} />
      <Container>
        <FaqInnerWrapper>
          {faqs.map((item, i) => (
            <SingleFaq
              faq={item}
              index={i}
              key={i}
              updateFaq={handleFaqClick}
            />
          ))}
        </FaqInnerWrapper>
      </Container>
    </FaqSectionWrapper>
  )
}

export default FaqSection
