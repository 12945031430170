import styled from 'styled-components'
import media from '../../utils/breakpoints'
import { Container } from '../../common/common-styles'

export const FeatureSectionWrapper = styled.section`
  padding: 3rem 0;
  background: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='1240' height='697'%3E%3Cswitch%3E%3Cg%3E%3Cg fill='%23f8f8ff'%3E%3Ccircle cx='258' cy='413' r='258'/%3E%3Ccircle cx='724' cy='187' r='187'/%3E%3Ccircle cx='689.5' cy='567.5' r='129.5'/%3E%3Ccircle cx='1110.5' cy='423.5' r='129.5'/%3E%3C/g%3E%3C/g%3E%3C/switch%3E%3C/svg%3E")
    2rem center / contain no-repeat;
  ${media.md} {
    ${Container} {
      background: url("data:image/svg+xml,%3Csvg width='1078' height='502' viewBox='0 0 1078 502' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.00048 434C7.00048 416 17.0005 421 15.0005 434C13.0005 447 -4.99952 475 5.00048 490C15.0005 505 38 508 40 471C42 434 386 529 497 457C608 385 702 272 854 273C1006 274 1068 300 1075 268C1082 236 1032 228 1017 219C1002 210 934 192 928 127C922 62 998 -23 1017 9C1036 41 1000 50 987 36' stroke='%2376FBDD' stroke-width='3'/%3E%3C/svg%3E%0A")
        center center / contain no-repeat;
    }
  }
`
export const FeatureSectionInner = styled.section`
  div.feature {
    padding: 1rem;
    margin: 1rem 0;
    border: 2px #f3f4f7 solid;
    border-radius: 8px;
    text-align: center;
    background: #fff;
    svg {
      display: block;
      margin: 0 auto;
    }
    p {
      font-size: 0.9rem;
    }
    h3 {
      font-size: 20px;
      padding: 1rem 0 0.5rem;
    }
  }

  ${media.md} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
`
export const FeatureSectionArea = styled.section``
